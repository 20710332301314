exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-ocus-20-js": () => import("./../../../src/pages/ocus20.js" /* webpackChunkName: "component---src-pages-ocus-20-js" */),
  "component---src-pages-ocus-40-js": () => import("./../../../src/pages/ocus40.js" /* webpackChunkName: "component---src-pages-ocus-40-js" */),
  "component---src-pages-ocus-js": () => import("./../../../src/pages/ocus.js" /* webpackChunkName: "component---src-pages-ocus-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-scanners-js": () => import("./../../../src/pages/scanners.js" /* webpackChunkName: "component---src-pages-scanners-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-download-page-template-js": () => import("./../../../src/templates/download-page-template.js" /* webpackChunkName: "component---src-templates-download-page-template-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page-template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */)
}

